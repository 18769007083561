@import '../../node_modules/bootstrap/scss/bootstrap.scss';
@import 'var';
.name{
  position: fixed;
  top: -3vh;
  left: 0;
  z-index: 9990;
  width: 100vw;
  @media (min-width: 992px) {
    width: 80vw;
    left: 10vw;
  }
  opacity: 0;
  .bg{
    background-color: $second;
    background-position: center right;
    background-repeat: no-repeat;
    background-size: auto 50vw;
    background-image: url(../assets/images/Warben_light.png);
    border-radius: 5px;
    position: absolute;
    left: 0;
    top: -5px;
    border-radius: 0;
    z-index: 9991;
    width: 85vw;
    height: 26vw;
    border: none;
    
  }
  img.logo{
    width: auto;
    height: 13vw;
    position: absolute;
    left: 2vw;
    top: calc(4vw + 10px);
    z-index: 9999;
  }
  img.namenwarbe{
    width: 30vw;
    height: auto;
    position: absolute;
    left: 70vw;
    top: 0;
    z-index: 9999;
  }
  

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    .bg{
      height: 15vw;
      width: 70vw;
      border-radius: 15px;
      border: solid 10px $prime;
    } 
    img.logo{
      height: 10vw;
      top: calc(2vw + 10px);
    }
    img.namenwarbe{
      width: 20vw;
      left: 65vw;
    }
  }
/*
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    .bg{
      height: 15vw;
    } 
  }

  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    .bg{
      height: 15vw;
    } 
  } */
}
.header{
  height: 100vh;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  background-color: $prime;
  .logoanimation{
    position: absolute;
    top:0;
    height: 100vh;
    display: flex;
    img{
      width: 100%;
      height: auto;
    }
  }
  .logo{
    left: 25vw;
    width: 50vw;
  }
  .warben{
    left: 9vw;
    width: 29vw;
    opacity: .6;
    img{
      height: calc(29vw / 1200 * 1717);
      margin-top: calc(50vh - 14.5vw / 1200 * 1717);
    }
    /* margin-bottom: 20vh;*/
  }
  .glas{
    width: 26vw;
    left: 66vw;
    /* margin-top: 15vh; */
    opacity: .4;
    img{
      height: calc(26vw / 1200 * 1227);
      margin-top: calc(50vh - 13vw / 1200 * 1227 + 4vw);
    }
  }
  .biene{
    width: 10vw;
    left: 62vw;
    /* margin-top: 15vh; */
    opacity: 1;
    img{
      height: calc(10vw / 1200 * 1421);
      margin-top: calc(50vh - 5vw / 1200 * 1421 - 10vw);
    }
  }
}
@media screen and (orientation: portrait) {
  
  .header{
    height: 100vw;
    .logoanimation{
      height: 100vw;
    }
    .warben{
      img{
        margin-top: calc(50vw - 14.5vw / 1200 * 1717);
      }
    }
    .glas{
      img{
        margin-top: calc(50vw - 13vw / 1200 * 1227 + 4vw);
      }
    }
    .biene{
      img{
        margin-top: calc(50vw - 5vw / 1200 * 1421 - 10vw);
      }
    }
  }
}
.trenner{
  padding: 0;
  margin-top: -2px;
  margin-bottom: -1px;
  width: 100%;
  overflow: hidden;
  img{
    width: 200%;
    height: auto;
  }
  @media (min-width: 992px) { 
    img{
      width: 100%;
    } 
  }
}
.content{
  margin-top: 30vw;
  margin-bottom: 30vw;
  background-color: $prime;
  border-radius: 15px;
  @media (min-width: 992px) {
    margin-top: 17vw;
    margin-bottom: 17vw;  
  }
  a, a:hover, a:active{
    color: $second;
    text-decoration: underline;
  }
}
.content, .impressum{
  font-size: 200%;
  h1{
    font-size: 30px;
    padding-bottom: 20px;
  }
  h2{
    font-size: 26px;
    padding-bottom: 17px;
  }
  h3{
    font-size: 23px;
  }
  h4,h5{
    font-size: 22px;
  }
  .text{
    padding: 60px 30px;
  }
}

.impressum{
  background-color: $prime;
  width: 100%;
  padding:  100px 0;
}























