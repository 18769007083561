// ###############################################
// ###############################################
// START Boilerplate (not Important for the Tutorial)
    
$prime: #c26b11;
$second: #2d2820;

body,
html{
    align-items: center;
    background-color: $second;
    display: flex;
    flex-direction: column;
    font-size: 10px;
    /* height: 100%; */
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 100%;
    color: $second;
}
*{
    box-sizing: border-box;
}
.bg_prime{
  background-color: $prime;
}
.bg_second{
  background-color: $second;
}